import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { NotfoundComponent } from './notfound/notfound.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RippleModule } from 'primeng/ripple';
import { provideHttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {CookieService} from 'ngx-cookie-service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment } from 'src/environments/environment';
import { RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';
@NgModule({
    declarations: [AppComponent, NotfoundComponent],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        RippleModule,
        BrowserAnimationsModule,
        SweetAlert2Module.forRoot(),
        FontAwesomeModule
    ],
    providers: [
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        provideHttpClient(),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.siteKeyV3 },
        CookieService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
