declare const require: any;
const packageJson = require('../../package.json');

export const environment = {
  mode: 'prod',
  urlpath: 'https://api.industryintel.com/api',
  siteKeyV3: '6LcXRRMqAAAAAPHVK2nJpepXZuFcdsAnP2No9PIK',
  production: true,
  version_number: packageJson.version,
  version_file: 'version.json'
};
