import { Component, OnDestroy} from '@angular/core';

@Component({
    selector: 'app-layout',
    templateUrl: './app.layout.component.html'
})
export class AppLayoutComponent implements OnDestroy {

    constructor() { }
    ngOnDestroy() {
    }
}
