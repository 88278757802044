import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { throwError, Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public url = environment.urlpath;

  constructor(private cookieService: CookieService) { }

  _errorHandler(error: any): Observable<never> {
    return throwError(() => new Error(error || 'Server Error on Server Occurred'));
  }

  getToken(): string | null {
    return this.cookieService.get('legislation_auth_token');
  }

  returnHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    const token = this.getToken();
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
  }
}
