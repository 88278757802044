<div class="not-found-container flex align-items-center justify-content-center">
    <div class="content-wrapper">
        <div class="error-card">
            <!-- Logo Section -->
            <div class="logo-section flex align-items-center justify-content-center">
                <img src="assets/layout/images/main-logo.svg" alt="Industry Intelligence Inc." class="logo">
            </div>
            
            <!-- Content Section -->
            <div class="content-section">
                <!-- Error Status -->
                <div class="text-center mb-5">
                    <i class="pi pi-exclamation-circle text-primary text-6xl mb-3"></i>
                    <h1 class="text-900 text-6xl font-bold m-0">404</h1>
                    <h2 class="text-700 text-3xl font-normal my-3">Page Not Found</h2>
                    <p class="text-600 line-height-3 mt-0 mb-5">
                        We couldn't find the page you're looking for, but don't worry! 
                        Check out our homepage to explore our market intelligence solutions.
                    </p>
                </div>

                <!-- Helpful Links -->
                <div class="grid">
                    <div class="col-12 md:col-6">
                        <a [routerLink]="['/']" pRipple 
                           class="helpful-link flex align-items-center no-underline">
                            <span class="icon-wrapper flex align-items-center justify-content-center bg-primary">
                                <i class="pi pi-home"></i>
                            </span>
                            <div class="ml-3 text-left">
                                <span class="text-900 font-medium block mb-1">Return Home</span>
                                <span class="text-600">Explore our solutions and demos</span>
                            </div>
                        </a>
                    </div>
                    
                    <div class="col-12 md:col-6">
                        <a href="mailto:support@industryintel.com" pRipple 
                           class="helpful-link flex align-items-center no-underline">
                            <span class="icon-wrapper flex align-items-center justify-content-center bg-orange-500">
                                <i class="pi pi-envelope"></i>
                            </span>
                            <div class="ml-3 text-left">
                                <span class="text-900 font-medium block mb-1">Contact Support</span>
                                <span class="text-600">We're here to help</span>
                            </div>
                        </a>
                    </div>
                </div>

                <!-- Marketing Message -->
                <a [routerLink]="['/']" class="no-underline">
                    <div class="marketing-section mt-5 p-3 border-round" pRipple>
                        <h3 class="text-primary text-xl font-medium mb-2">
                            Discover More with Industry Intelligence
                            <i class="pi pi-arrow-right ml-2"></i>
                        </h3>
                        <p class="text-700 line-height-3 m-0">
                            Experience how our market intelligence platform can transform your business decision-making. 
                            Explore interactive demos, industry insights, and powerful analytics tools 
                            that give you the competitive edge.
                        </p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>