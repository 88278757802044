import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    private readonly TOKEN_KEY = 'servicedemo-auth-token';

    constructor() {}

    /**
     * Stores token in localStorage
     * @param token Token string to store
     */
    storeToken(token: string): void {
        localStorage.setItem(this.TOKEN_KEY, token);
    }

    /**
     * Retrieves token from localStorage
     * @returns Token string or null if not found
     */
    getToken(): string | null {
        return localStorage.getItem(this.TOKEN_KEY);
    }

    /**
     * Removes token from localStorage
     */
    removeToken(): void {
        localStorage.removeItem(this.TOKEN_KEY);
    }
} 