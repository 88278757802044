<div class="activation-wrapper">
    <div class="activation-card">
        <!-- Welcome Section -->
        <header class="card-header">
            <div class="logo-container">
                <img src="assets/layout/images/main-logo.svg" alt="Company Logo">
            </div>
            <div class="divider"></div>
            <h1>Welcome to Your Service Portal</h1>
            <p class="welcome-text">We're setting up your service access. This will only take a moment.</p>
            
            <!-- Attractive Countdown -->
            <div *ngIf="showWelcome" class="countdown-container">
                <div class="countdown-circle">
                    <svg class="countdown-svg">
                        <circle class="countdown-background" cx="50%" cy="50%" r="45%"/>
                        <circle class="countdown-progress" 
                                cx="50%" 
                                cy="50%" 
                                r="45%"
                                [style.stroke-dashoffset]="getCircleOffset()"/>
                    </svg>
                    <span class="countdown-number">{{countdown}}</span>
                </div>
                <p class="countdown-text">Starting activation...</p>
            </div>
        </header>

        <!-- Progress Steps -->
        <div class="steps-container" [class.dimmed]="showWelcome">
            <div class="step" *ngFor="let stepConfig of steps; let i = index"
                 [ngClass]="{
                     'active': step === i && isActivating,
                     'complete': (step > i) || (step === 3 && i === 2),
                     'error': stepError === i
                 }">
                
                <div class="step-indicator">
                    <i class="pi" [ngClass]="getStepIcon(i)"></i>
                </div>
                
                <div class="step-content">
                    <span class="step-label">{{stepConfig.label}}</span>
                    <div class="error-message" *ngIf="stepError === i">
                        <ul>
                            <li *ngFor="let error of currentErrorMessage">{{error}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <!-- Action Section -->
        <footer class="card-footer">
            <div *ngIf="activationError" class="error-actions">
                <button class="primary-button" (click)="retryActivation()">
                    <i class="pi pi-refresh"></i>
                    Try Again
                </button>
                <a href="mailto:support@example.com" class="secondary-button">
                    <i class="pi pi-envelope"></i>
                    Contact Support
                </a>
            </div>

            <div *ngIf="!isActivating && !activationError && step === 3" 
                 class="success-message">
                <div class="success-content">
                    <i class="pi pi-check-circle success-icon"></i>
                    <div class="message-content">
                        <span class="success-title">Setup Complete!</span>
                        <span class="redirect-text">Redirecting in {{redirectCountdown}} seconds...</span>
                    </div>
                </div>
                <div class="progress-container">
                    <div class="progress-bar">
                        <div class="progress-fill" [style.width.%]="getProgressPercentage()"></div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div> 