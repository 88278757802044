import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

    constructor(private primengConfig: PrimeNGConfig, private renderer: Renderer2) { }

    ngOnInit() {
        this.primengConfig.ripple = true;
    }
    ngAfterViewInit(): void {

    }
}    
