import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment.prod';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
  styles: [
    `.lightbulb-icon { color: blue; transition: color 0.3s; }`,
    `.lightbulb-icon:hover { color: yellow; }`
  ]
})
export class AppFooterComponent implements OnInit {
  currentYear: number;
  version: string;

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.version = environment.version_number;
  }
}
