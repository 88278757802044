import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HeaderService } from './shared/header.service';

@Injectable({
  providedIn: 'root'
})
export class ActivationServiceDemoService {
  private url = environment.urlpath;

  constructor(
    private http: HttpClient, 
    private headerSrv: HeaderService
  ) {}

  /**
   * Activates the service demo with a verification token
   * @param token - The verification token received via email
   * @returns An Observable that emits the activation response
   */
  ActivateServiceDemo(token: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('token', token);
    return this.http.post(`${this.url}/service-demo/activate`, null, { params: params, headers: this.headerSrv.returnHeaders() }).pipe(catchError(this.headerSrv._errorHandler));
  }
} 