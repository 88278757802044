import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { ActivationServiceDemoComponent } from './activation-service-demo/activation-service-demo.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppLayoutComponent,
                children: [
                    { path: '', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule)},
                    { path: 'legislation-monitor', loadChildren: () => import('./legislation-monitor/legislation-monitor.module').then(m => m.LegislationMonitorModule)},
                    { path: 'customer-insights', loadChildren: () => import('./customer-insights/customer-insights.module').then(m => m.CustomerInsightsModule)},
                    { path: 'competitive-edge', loadChildren: () => import('./competitive-edge/competitive-edge.module').then(m => m.CompetitiveEdgeModule)},
                    { path: 'supplier-iq', loadChildren: () => import('./supplier-iq/supplier-iq.module').then(m => m.SupplierIQModule)},
                ]
            },
            { path: 'activate-service-demo/:token', component: ActivationServiceDemoComponent },
            { path: 'activate-service-demo', redirectTo: '/notfound' },
            { path: 'notfound', component: NotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
